/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Constants from '../../../src/constants'

function SEO({ description, lang, meta, image, title, pathname }) {
  const queryData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image {
              src
              width
              height
            }
          }
        }
      }
    `
  )
  const siteUrl = Constants.BASE_URL
  const defaultImage = {
    src: `${siteUrl}${queryData.site.siteMetadata.image.src}`,
    width: queryData.site.siteMetadata.image.width,
    height: queryData.site.siteMetadata.image.height,
  }
  const metaImage = image || defaultImage
  const baseDescription = queryData.site.siteMetadata.description
  const metaDescription = description || baseDescription
  const baseTitle = queryData.site.siteMetadata.title
  const canonical = pathname ? `${siteUrl ? siteUrl.slice(0, -1) : siteUrl}${pathname}` : null
  const mainTitle = title.length > 1 ? `${title} – ${baseTitle}` : baseTitle
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={mainTitle}
    >
      <link rel='canonical' href={canonical}/>
      <meta name='image' content={metaImage.src} />
      <meta name='description' content={metaDescription} />
      <meta name='author' content={queryData.site.siteMetadata.author}/>
      <meta name='copyright' content='© Copyright - Ecco'/>
      <meta name='location' content='Paris, FRANCE'/>

      {/* OG meta */}
      <meta property='og:title' content={mainTitle}/>
      <meta property='og:description' content={metaDescription}/>
      <meta property='og:image' content={metaImage.src}/>
      <meta property='og:url' content={canonical}/>
      <meta property='og:site_name' content={mainTitle}/>
      <meta property='og:type' content={'website'} />

      {/* Twitter meta */}
      <meta name='twitter:card' content='summary_large_image'/>
      <meta name='twitter:site' content={'@'}/>
      <meta name='twitter:title' content={mainTitle}/>
      <meta name='twitter:description' content={metaDescription}/>
      <meta name='twitter:image:src' content={metaImage.src}/>

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string
}

export default SEO
